/* eslint-disable no-lonely-if */
/* eslint-disable import/no-cycle */

import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";
import forEach from "lodash/forEach";
import assign from "lodash/assign";
import keys from "lodash/keys";
import join from "lodash/join";
import replace from "lodash/replace";

import PlanModel from "./Plan";
import {
  PLAN_SELECT,
  PLAN_EDIT_START,
  PLAN_EDIT_STOP,
  PLAN_CLEAR_CURRENT,
  PLAN_RESET_CURRENT,
  PLAN_DISPLAY_ERRORS,
  PLAN_HIDE_ERRORS,
  PLAN_SUCCESS_MESSAGE,
  PLAN_MODEL_ID,
  PLAN_MODEL_NAME,
  PLAN_SET_IS_NEW,
  PLAN_MODEL_FLIGHT_ID,
  PLAN_MODEL_SENT_TO_MBS,
  PLAN_RESPONSE_ORDER_NUMBERS,
  PLAN_CREATE_FLIGHT_SUCCESS_MESSAGE,
  PLAN_UPDATE_FLIGHT_SUCCESS_MESSAGE,
  PLAN_SET_ACTUAL_MESSAGE,
  PLAN_SELECTED_ORDERS,
  PLAN_SELECT_ORDER_STATUS,
  PLAN_SELECT_ORDER_NEXT_STATUS,
  PLAN_CLEAR_ORDER_STATUS,
  PLAN_CLEAR_ORDERS,
  PLAN_INSERT_ORDER_FOR_DIVIDE,
  PLAN_REMOVE_ORDER_FOR_DIVIDE,
  PLAN_CLEAR_ORDERS_FOR_DIVIDE,
  PLAN_INITIALIZE_INSERTION_DATES,
  PLAN_UPDATE_INSERTION_DATES,
  PLAN_CLEAR_INSERTION_DATES,
  PLAN_DIVIDE_ORDERS_INVOICE_DATE_NULL_ERROR_MESSAGE,
  PLAN_DIVIDE_ORDERS_INVOICE_DATE_UNIQUE_ERROR_MESSAGE,
  PLAN_DIVIDE_ORDERS_SPLIT_PERCENTAGE_NULL_ERROR_MESSAGE,
  PLAN_DIVIDE_ORDERS_SPLIT_PERCENTAGE_INCORRECT_ERROR_MESSAGE,
  PLAN_DIVIDE_ORDERS_ERROR_MESSAGE,
  PLAN_DIVIDE_ORDERS_SUCCESS_MESSAGE,
  PLAN_SET_FLIGHT_MBS,
  PLAN_RESET_FLIGHT_MBS,
  PLAN_SET_PLAN_MBS,
  PLAN_RESET_PLAN_MBS,
  DELETE_TARGET_METRICS_KPI_PROPERTY,
  PLAN_UPDATE_IS_DIVIDE_INSERTION_EVENLY
} from "./planConstants";
import {
  selectCurrentPlan,
  selectIsEditingPlan,
  selectPlanIsNew,
  getEditingPlan,
  getPlanForSave,
  getPlanActual,
  doesPlanHaveErrors,
  selectedOrderStatus,
  selectedOrders,
  selectOrdersWithStatus,
  selectedNextOrderStatus,
  selectSelectedOrdersForDivide,
  selectSelectedInsertionDates,
  getPlanById,
  selectIsDivideInsertionEvenly
} from "./planSelectors";

import { CAMPAIGN_MODEL_ID } from "../Campaign/campaignConstants";
import {
  startEditingCampaign,
  stopEditingCampaign
} from "../Campaign/campaignActions";
import {
  getCampaign,
  selectCurrentCampaign
} from "../Campaign/campaignSelectors";

import { ORDER_MODEL_NAME } from "../Order/orderConstants";
import { clearCurrentOrder } from "../Order/orderActions";
import { getOrderById } from "../Order/orderSelectors";
import {
  clearCurrentInsertions,
  deleteInsertions,
  selectInsertions
} from "../Insertion/insertionActions";
import { getInsertionIDsByOrderId } from "../Insertion/insertionSelectors";

import { deletePlansTargetMetricsKpisByPlanId } from "../PlanTargetMetricsKpi/planTargetMetricsKpiActions";

import {
  editItemAttributes,
  editExistingItem,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import generateUrl from "../common/utils/urlUtils";
import {
  getQueryObj,
  generateParamObj,
  getMatchingProperties,
  getPathValue,
  getMappedObject,
  hasNullOrUndefinedOrEmptyValues,
  hasUniqueValues,
  sumArrayValues
} from "../common/utils/modelUtils";

import {
  CREATE_PLAN,
  CREATE_PLAN_COPY_PLAN,
  UPDATE_PLAN,
  CREATE_FLIGHT,
  UPDATE_FLIGHT,
  SET_ACTUAL,
  ORDER_STATUS_CHANGE,
  ORDERS_DIVIDE,
  INIT_COPY_PLAN,
  DELETE_COPY_PLAN,
  GET_PLAN_EXPORT,
  GET_PLAN_EXPORT_COMPONENTS,
  DO_NOT_NOTIFY_INSERTION_CHANGE,
  GET_GROUPS,
  PROCESS_PLAN_SIGNATURE,
  GET_DIGITAL_SIGNATURE_AGREEMENT_STATUS,
  GET_SIGNED_AGREEMENT
} from "../../../configurations/apiUrls";

import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  enqueueApplicationErrorMessage,
  openOrderStatusChangeDialog,
  closeOrderStatusChangeDialog,
  setOrderStatusChangeDialogTitle,
  apiCall,
  setCheckAllOrders
} from "../../actions/applicationActions";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";

import { selectAgency } from "../../selectors/applicationSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";

import { POST, PUT, GET, DELETE } from "../../constants/applicationConstants";
import { isNullOrUndefined } from "../../../functions/util";
import { ORDER_STATUS_APPROVED } from "../../../configurations/appConstants";
import { setActiveStep } from "../../actions/copyPlanActions";
import {
  selectProcess,
  selectActiveStep
} from "../../selectors/copyPlanSelectors";
import download from "../../../functions/download";
import {
  PLAN_EXTENSION_MODEL_ID,
  PLAN_EXTENSION_MODEL_NAME
} from "../PlanExtension/planExtensionConstants";
import {
  CREATE_PLAN_STEP_FAILED,
  stepsEnum
} from "../../constants/copyPlanConstants";
import { UPDATE_ORDERS_WITH_INSERTION_CHANGE } from "../../constants/campaignDashboardConstants";
import { selectUserName } from "../../selectors/userProfileSelectors";

function setMediaData() {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = PlanModel.apiConfiguration;
    const apiData = getApiData(state);
    const editingData = getEditingPlan(state);
    const currentPlan = selectCurrentPlan(state);
    const agencyID = selectAgency(state);
    forEach(configuration, (prop, key) => {
      if (prop.path) {
        const prevValue = get(editingData, key);
        const value = getPathValue(prop, editingData, apiData, agencyID);
        if (!isNullOrUndefined(value) && value !== "" && prevValue !== value) {
          dispatch(
            editItemAttributes(PLAN_MODEL_NAME, currentPlan, { [key]: value })
          );
        }
      }
    });
  };
}

export function loadPlanMediaData(data) {
  return (dispatch, getState) => {
    const configuration = PlanModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

export function loadEditingPlanMediaData(data) {
  return (dispatch, getState) => {
    const configuration = PlanModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url, setMediaData));
      }
    });
  };
}

export const clearCurrentPlan = () => dispatch =>
  dispatch({ type: PLAN_CLEAR_CURRENT });

export const resetCurrentPlan = () => dispatch =>
  dispatch({ type: PLAN_RESET_CURRENT });

export function clearPlan() {
  return dispatch => {
    dispatch(clearCurrentPlan());
    dispatch(clearCurrentOrder());
    dispatch(clearCurrentInsertions());
  };
}

export function startEditingPlan() {
  return (dispatch, getState) => {
    const currentPlan = selectCurrentPlan(getState());

    dispatch(editExistingItem(PLAN_MODEL_NAME, currentPlan));
    dispatch({ type: PLAN_EDIT_START });

    // Load media - improve
    const editingData = getEditingPlan(getState());
    dispatch(loadEditingPlanMediaData(editingData));
  };
}

export function stopEditingPlan() {
  return (dispatch, getState) => {
    const currentPlan = selectCurrentPlan(getState());

    dispatch(stopEditingItem(PLAN_MODEL_NAME, currentPlan));
    dispatch({ type: PLAN_EDIT_STOP });
    dispatch({ type: PLAN_HIDE_ERRORS });
  };
}

export function cancelPlan() {
  return (dispatch, getState) => {
    dispatch(stopEditingPlan());
    dispatch(stopEditingCampaign());
    const isNew = selectPlanIsNew(getState());
    if (isNew) {
      dispatch(clearCurrentPlan());
    } else {
      dispatch(resetCurrentPlan());
    }
  };
}

export function editPlan() {
  return dispatch => {
    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
  };
}

export function selectPlan(planID) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingPlan(state);

    if (isEditing) {
      dispatch(stopEditingPlan());
      dispatch(stopEditingCampaign());
    }

    dispatch(clearPlan());
    dispatch({
      type: PLAN_SELECT,
      payload: {
        currentPlan: planID
      }
    });
  };
}

export function addNewPlan() {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const campaignId = selectCurrentCampaign(state);
    const campaign = getCampaign(state);
    const inheritObject = getMappedObject(
      PlanModel.inheritConfiguration,
      campaign
    );

    const { Plan } = session;

    const id = cuid();

    const newPlan = Plan.generate({
      id,
      ...inheritObject,
      campaign: campaignId
    });

    const planContents = newPlan.toJSON();

    dispatch(startEditingCampaign());
    dispatch(editNewItem(PLAN_MODEL_NAME, id, planContents));
    dispatch({ type: PLAN_SET_IS_NEW });
    dispatch({
      type: PLAN_SELECT,
      payload: {
        currentPlan: id
      }
    });
    dispatch({ type: PLAN_EDIT_START });

    dispatch(loadEditingPlanMediaData({ ...campaign, ...planContents }));

    return id;
  };
}

export const copyPlan = () => (dispatch, getState) => {
  const state = getState();
  const session = getUnsharedEntitiesSession(state);
  const campaignId = selectCurrentCampaign(state);
  const planId = selectCurrentPlan(state);

  const campaign = getCampaign(state);
  const inheritObject = getMappedObject(
    PlanModel.inheritConfiguration,
    campaign
  );

  const { Plan } = session;
  const id = cuid();
  const newPlan = Plan.generate({
    id,
    ...inheritObject,
    [CAMPAIGN_MODEL_ID]: campaignId
  });
  const planContents = newPlan.toJSON();

  const planData = getPlanById(state, planId);
  set(planContents, "planName", planData.planName);
  set(planContents, "mediaTypeId", planData.mediaTypeId);
  set(planContents, "totalBudget", planData.totalBudget);

  dispatch(startEditingCampaign());
  dispatch(editNewItem(PLAN_MODEL_NAME, id, planContents));
  dispatch(selectPlan(id));
  dispatch({ type: PLAN_SET_IS_NEW });
  dispatch({ type: PLAN_EDIT_START });

  dispatch(loadEditingPlanMediaData({ ...campaign, ...planContents }));

  return Promise.resolve(id);
};

export function displayPlanErrors() {
  return { type: PLAN_DISPLAY_ERRORS };
}

export function createPlan() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesPlanHaveErrors(state);
    if (hasErrors) {
      dispatch(displayPlanErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const currentCampaign = selectCurrentCampaign(state);
    const data = getPlanForSave(state);
    const url = generateUrl(CREATE_PLAN, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign
    });

    return dispatch(apiCall(POST, url, data)).then(
      response => {
        const id = get(response, PLAN_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(PLAN_MODEL_NAME, id, responseData));
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(selectPlan(id));
        dispatch(enqueueNotificationMessage(PLAN_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

/* eslint-disable no-extra-boolean-cast */
export const createPlanCopyPlan = () => (dispatch, getState) => {
  const state = getState();

  const hasErrors = doesPlanHaveErrors(state);
  if (hasErrors) {
    dispatch(displayPlanErrors());
    return Promise.reject();
  }

  const agency = selectAgency(state);
  const processId = selectProcess(state);
  const currentCampaign = selectCurrentCampaign(state);
  const data = getPlanForSave(state);
  const url = generateUrl(CREATE_PLAN_COPY_PLAN, {
    agency,
    processId,
    [CAMPAIGN_MODEL_ID]: currentCampaign
  });

  return dispatch(apiCall(POST, url, data)).then(
    response => {
      const { isSuccessful, plan, planExtension, step } = response || {};

      if (isSuccessful) {
        const planId = get(plan, PLAN_MODEL_ID);
        const planData = set(plan, "id", planId);
        dispatch(commitData(PLAN_MODEL_NAME, planId, planData));

        if (!!planExtension) {
          const planExtensionid = get(planExtension, PLAN_EXTENSION_MODEL_ID);
          const planExtensionData = set(planExtension, "id", planExtensionid);
          dispatch(
            commitData(
              PLAN_EXTENSION_MODEL_NAME,
              planExtensionid,
              planExtensionData
            )
          );
        }

        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(selectPlan(planId));
        dispatch(setActiveStep(step));

        dispatch(enqueueNotificationMessage(PLAN_SUCCESS_MESSAGE));
      } else {
        dispatch(enqueueApiErrorMessage(CREATE_PLAN_STEP_FAILED));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export function updatePlan() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesPlanHaveErrors(state);
    if (hasErrors) {
      dispatch(displayPlanErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const currentCampaign = selectCurrentCampaign(state);
    const currentPlan = selectCurrentPlan(state);
    const data = getPlanForSave(state);
    const url = generateUrl(UPDATE_PLAN, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign,
      [PLAN_MODEL_ID]: currentPlan
    });

    return dispatch(apiCall(PUT, url, data)).then(
      response => {
        const id = get(response, PLAN_MODEL_ID);
        const deleteTargetMetricsKpiData = get(
          response,
          DELETE_TARGET_METRICS_KPI_PROPERTY
        );
        const responseData = set(response, "id", id);
        dispatch(commitData(PLAN_MODEL_NAME, id, responseData));

        if (deleteTargetMetricsKpiData) {
          dispatch(deletePlansTargetMetricsKpisByPlanId(id));
        }

        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(selectPlan(id));
        dispatch(enqueueNotificationMessage(PLAN_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateSelectedOrders(newArr) {
  return dispatch => {
    dispatch({
      type: PLAN_SELECTED_ORDERS,
      payload: {
        selectedOrdersInPlan: newArr
      }
    });
  };
}

export function diselectOrderIfApproved(orderData) {
  return (dispatch, getState) => {
    if (orderData.status === ORDER_STATUS_APPROVED) {
      const selectedOrderIds = selectedOrders(getState());
      const newArr = selectedOrderIds.filter(id => {
        return id !== orderData.orderId;
      });
      dispatch(updateSelectedOrders(newArr));
    }
  };
}

export function resetPlanData(currentPlan, data) {
  return dispatch => {
    const configuration = PlanModel.apiConfiguration;
    const defaults = PlanModel.defaultAttributes;
    const name = keys(data)[0];
    const properties = getMatchingProperties(configuration, name);
    forEach(properties, propertyName => {
      dispatch(
        editItemAttributes(PLAN_MODEL_NAME, currentPlan, {
          [propertyName]: defaults[propertyName]
        })
      );
    });
  };
}

export function setPlanValue(data) {
  return (dispatch, getState) => {
    const currentPlan = selectCurrentPlan(getState());
    const editingData = getEditingPlan(getState());
    const planData = assign({}, editingData, data);

    dispatch(editItemAttributes(PLAN_MODEL_NAME, currentPlan, data));
    dispatch(resetPlanData(currentPlan, data));
    dispatch(loadEditingPlanMediaData(planData));
  };
}

export function createFlight(planId) {
  return (dispatch, getState) => {
    const agencyID = selectAgency(getState());

    const url = generateUrl(CREATE_FLIGHT, {
      agency: agencyID,
      [PLAN_MODEL_ID]: planId
    });

    return dispatch(apiCall(POST, url)).then(
      response => {
        const id = get(response, PLAN_MODEL_ID);
        const sendToMbsResult = get(response, PLAN_MODEL_SENT_TO_MBS);
        const flightIdResult = get(response, PLAN_MODEL_FLIGHT_ID);
        const orderNumbers = get(response, PLAN_RESPONSE_ORDER_NUMBERS);
        const message = PLAN_CREATE_FLIGHT_SUCCESS_MESSAGE;

        dispatch(
          commitData(PLAN_MODEL_NAME, id, {
            planId: id,
            sendToMbs: sendToMbsResult,
            flightId: flightIdResult
          })
        );
        dispatch(selectPlan(id));
        dispatch(enqueueNotificationMessage(`${message}${orderNumbers}.`));
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateFlight(planId, flightId) {
  return (dispatch, getState) => {
    const agencyID = selectAgency(getState());

    const url = generateUrl(UPDATE_FLIGHT, {
      agency: agencyID,
      [PLAN_MODEL_ID]: planId,
      [PLAN_MODEL_FLIGHT_ID]: flightId
    });

    return dispatch(apiCall(PUT, url)).then(
      response => {
        const id = get(response, PLAN_MODEL_ID);
        const sendToMbsResult = get(response, PLAN_MODEL_SENT_TO_MBS);
        const flightIdResult = get(response, PLAN_MODEL_FLIGHT_ID);
        const orderNumbers = get(response, PLAN_RESPONSE_ORDER_NUMBERS);
        const message = PLAN_UPDATE_FLIGHT_SUCCESS_MESSAGE;

        dispatch(
          commitData(PLAN_MODEL_NAME, id, {
            planId: id,
            sendToMbs: sendToMbsResult,
            flightId: flightIdResult
          })
        );
        dispatch(selectPlan(id));
        dispatch(enqueueNotificationMessage(`${message}${orderNumbers}.`));
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function setActual() {
  return (dispatch, getState) => {
    const agencyID = selectAgency(getState());
    const planId = selectCurrentPlan(getState());
    const isActual = getPlanActual(getState());
    const url = generateUrl(SET_ACTUAL, {
      agency: agencyID,
      [PLAN_MODEL_ID]: planId
    });

    return dispatch(apiCall(PUT, url)).then(
      () => {
        dispatch(
          commitData(PLAN_MODEL_NAME, planId, {
            planId,
            isActual: !isActual
          })
        );
        dispatch(enqueueNotificationMessage(`${PLAN_SET_ACTUAL_MESSAGE}`));
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function setSelectedOrderStatusInPlan(statusId, orderId, selected) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedOrderList = selectedOrders(state);
    const selectedStatus = selectedOrderStatus(state);

    if (selected) {
      if (!selectedStatus) {
        dispatch({
          type: PLAN_SELECT_ORDER_STATUS,
          payload: {
            selectedOrderStatusInPlan: statusId
          }
        });

        const newOrderList = [];
        newOrderList.push(orderId);

        dispatch(updateSelectedOrders(newOrderList));
      } else {
        if (selectedStatus === statusId) {
          const newOrderList = Array.from(selectedOrderList);
          newOrderList.push(orderId);

          dispatch(updateSelectedOrders(newOrderList));
        }
      }
    } else {
      if (selectedOrderList.length > 0) {
        const idx = selectedOrderList.indexOf(orderId);
        const newOrderList = Array.from(selectedOrderList);
        newOrderList.splice(idx, 1);

        dispatch(updateSelectedOrders(newOrderList));

        if (newOrderList.length === 0) {
          dispatch({
            type: PLAN_CLEAR_ORDER_STATUS
          });
        }
      }
    }
  };
}

export function setOrderForDivide(orderId, checked) {
  return dispatch => {
    if (checked) {
      dispatch({
        type: PLAN_INSERT_ORDER_FOR_DIVIDE,
        payload: {
          orderId
        }
      });
    } else {
      dispatch({
        type: PLAN_REMOVE_ORDER_FOR_DIVIDE,
        payload: {
          orderId
        }
      });
    }
  };
}

export function clearOrdersForDivide() {
  return dispatch => {
    dispatch({
      type: PLAN_CLEAR_ORDERS_FOR_DIVIDE
    });
  };
}

export function initializeInsertionDatesArray(divideCount) {
  return dispatch => {
    dispatch({
      type: PLAN_INITIALIZE_INSERTION_DATES,
      payload: {
        divideCount
      }
    });
  };
}

export function updateInsertionDatesArray(index, value, property) {
  return dispatch => {
    dispatch({
      type: PLAN_UPDATE_INSERTION_DATES,
      payload: {
        index,
        value,
        property
      }
    });
  };
}

export function clearInsertionDatesArray() {
  return dispatch => {
    dispatch({
      type: PLAN_CLEAR_INSERTION_DATES
    });
  };
}

export function setSelectAllOrdersWithStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const orderIdsWithStatus = selectOrdersWithStatus(state);

    const newOrderList = Array.from(orderIdsWithStatus);
    dispatch(updateSelectedOrders(newOrderList));
  };
}

export function setDeselectAllOrdersWithStatus() {
  return dispatch => {
    dispatch({
      type: PLAN_CLEAR_ORDER_STATUS
    });

    dispatch({
      type: PLAN_CLEAR_ORDERS
    });
  };
}

export function openDialog() {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);
    const currentCampaign = selectCurrentCampaign(state);
    const selectedOrderIds = selectOrdersWithStatus(state);

    const url = generateUrl(
      ORDER_STATUS_CHANGE,
      {
        agency: agencyID,
        [CAMPAIGN_MODEL_ID]: currentCampaign
      },
      {
        orderids: join(selectedOrderIds, ",")
      }
    );

    dispatch(apiCall(GET, url)).then(response => {
      if (response) {
        dispatch(openOrderStatusChangeDialog());
        dispatch(setOrderStatusChangeDialogTitle(response.nextStatus));
        dispatch(
          {
            type: PLAN_SELECT_ORDER_NEXT_STATUS,
            payload: {
              selectedOrderNextStatusInPlan: response.nextStatusId
            }
          },
          error => {
            dispatch(enqueueApiErrorMessage(error));
          }
        );
      }
    });
  };
}

export function closeDialog() {
  return dispatch => {
    dispatch(closeOrderStatusChangeDialog());
  };
}

export function changeStatusForSelectedOrders() {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);
    const currentCampaign = selectCurrentCampaign(state);
    const selectedOrderIds = selectedOrders(state);
    const nextStatus = selectedNextOrderStatus(state);

    const url = generateUrl(ORDER_STATUS_CHANGE, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign
    });

    const body = { orderids: selectedOrderIds };
    dispatch(apiCall(PUT, url, body)).then(
      response => {
        if (response) {
          selectedOrderIds.forEach(id => {
            const responseData = set(
              {
                statusId: nextStatus
              },
              "id",
              id
            );
            dispatch(commitData(ORDER_MODEL_NAME, id, responseData));
          });

          dispatch(setDeselectAllOrdersWithStatus());
          dispatch(setCheckAllOrders(false));
          dispatch(closeOrderStatusChangeDialog());
        }
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function divideOrders() {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);
    const orders = selectSelectedOrdersForDivide(state);
    const insertionData = selectSelectedInsertionDates(state);
    const isDivideInsertionsEvenly = selectIsDivideInsertionEvenly(state);
    const insertionInvoiceDates = insertionData.map(e => {
      return e.invoiceDate;
    });

    // validation
    if (hasNullOrUndefinedOrEmptyValues(insertionInvoiceDates)) {
      dispatch(
        enqueueApplicationErrorMessage(
          PLAN_DIVIDE_ORDERS_INVOICE_DATE_NULL_ERROR_MESSAGE
        )
      );
      return Promise.reject();
    }

    if (!hasUniqueValues(insertionInvoiceDates)) {
      dispatch(
        enqueueApplicationErrorMessage(
          PLAN_DIVIDE_ORDERS_INVOICE_DATE_UNIQUE_ERROR_MESSAGE
        )
      );
      return Promise.reject();
    }

    if (!isDivideInsertionsEvenly) {
      const insertionSplitPercentage = insertionData.map(e => {
        return e.splitPercentage;
      });

      if (hasNullOrUndefinedOrEmptyValues(insertionSplitPercentage)) {
        dispatch(
          enqueueApplicationErrorMessage(
            PLAN_DIVIDE_ORDERS_SPLIT_PERCENTAGE_NULL_ERROR_MESSAGE
          )
        );
        return Promise.reject();
      }

      if (sumArrayValues(insertionSplitPercentage) !== 100) {
        dispatch(
          enqueueApplicationErrorMessage(
            PLAN_DIVIDE_ORDERS_SPLIT_PERCENTAGE_INCORRECT_ERROR_MESSAGE
          )
        );
        return Promise.reject();
      }
    }

    const url = generateUrl(ORDERS_DIVIDE, {
      agency: agencyID
    });

    const body = {
      orders,
      insertionData,
      isDivideInsertionsEvenly
    };

    return dispatch(apiCall(PUT, url, body)).then(
      response => {
        const { validOrders, invalidOrders, isSuccessful } = response;
        if (validOrders) {
          const validOrderIDs = [];

          forEach(validOrders, order => {
            const { orderId } = order;
            const { marathonOrderId } = getOrderById(state, orderId);
            const oldInsertionIds = getInsertionIDsByOrderId(state, orderId);

            dispatch(deleteInsertions(oldInsertionIds));
            dispatch(commitData(ORDER_MODEL_NAME, orderId, order));
            dispatch(selectInsertions());

            validOrderIDs.push(marathonOrderId);

            dispatch({
              type: UPDATE_ORDERS_WITH_INSERTION_CHANGE,
              payload: { orderId }
            });
          });

          dispatch(
            enqueueNotificationMessage(
              replace(
                PLAN_DIVIDE_ORDERS_SUCCESS_MESSAGE,
                "{0}",
                join(validOrderIDs, ", ")
              )
            )
          );
        }

        if (invalidOrders) {
          enqueueApplicationErrorMessage(
            replace(
              PLAN_DIVIDE_ORDERS_ERROR_MESSAGE,
              "{0}",
              join(invalidOrders, ", ")
            )
          );
        }

        return isSuccessful;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function initCopyPlan(id) {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);

    const url = generateUrl(INIT_COPY_PLAN, {
      agency: agencyID
    });

    const body = { planId: id };

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        if (response) {
          return response.processId;
        }

        return null;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const cancelCopyPlan = agency => (dispatch, getState) => {
  const state = getState();
  const processId = selectProcess(state);
  const activeStep = selectActiveStep(state);

  const url = generateUrl(DELETE_COPY_PLAN, {
    agency,
    processId
  });

  if (processId && activeStep !== stepsEnum.FINISH) {
    return dispatch(apiCall(DELETE, url)).then(
      () => {},
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  }
};

export const downloadExcel = (
  checkedComponentIdsForExport,
  footnotes,
  exportRatecard,
  marathonPlanId,
  planName,
  checkedOrderIds
) => (dispatch, getState) => {
  const state = getState();
  const currentPlan = selectCurrentPlan(state);
  const agency = selectAgency(state);

  const url = generateUrl(GET_PLAN_EXPORT, {
    agency,
    [PLAN_MODEL_ID]: currentPlan
  });

  const body = {
    componentIds: checkedComponentIdsForExport,
    footnotesCustom: footnotes,
    exportRatecard,
    orderIds: checkedOrderIds
  };

  planName = planName.replace(/[<>:"/\\|?*]+/g, "");

  const fileName = `GO-Export-${marathonPlanId}-${planName}`.slice(0, 250);

  return dispatch(apiCall(POST, url, body))
    .then(response => {
      return response.blob();
    })
    .then(blob => download(blob, `${fileName}.xlsx`))
    .then(() =>
      dispatch(enqueueNotificationMessage("File is successfully downloaded"))
    )
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage("There was problem while downloading file")
      )
    );
};

export const sendToSignature = (
  checkedComponentIdsForExport,
  footnotes,
  exportRatecard,
  checkedOrderIds,
  emailText,
  emailAddress,
  ccEmailAddresses,
  documentName,
  group
) => (dispatch, getState) => {
  const state = getState();
  const currentPlan = selectCurrentPlan(state);
  const agency = selectAgency(state);
  const url = generateUrl(PROCESS_PLAN_SIGNATURE, {
    agency,
    [PLAN_MODEL_ID]: currentPlan
  });

  const body = {
    componentIds: checkedComponentIdsForExport,
    footnotesCustom: footnotes,
    exportRatecard,
    orderIds: checkedOrderIds,
    emailMessage: emailText,
    recipientEmail: emailAddress,
    ccEmail: ccEmailAddresses,
    agreementName: documentName,
    groupId: group
  };

  dispatch(apiCall(POST, url, body))
    .then(
      response => {
        const { message, isSuccessful } = response || {};
        if (isSuccessful) {
          dispatch(
            enqueueNotificationMessage(
              "Document has been sent to Signatures successfully"
            )
          );
        } else {
          dispatch(enqueueApiErrorMessage(message));
        }
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    )
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage(
          "There was problem while sending document to signatures"
        )
      )
    );
};

export const downloadSignedAgreement = agreementId => (dispatch, getState) => {
  const state = getState();
  const currentPlan = selectCurrentPlan(state);
  const agency = selectAgency(state);
  const identifier = agreementId.split("|")[1];
  const documentName = agreementId.split("|")[0];

  const url = generateUrl(GET_SIGNED_AGREEMENT, {
    agency,
    [PLAN_MODEL_ID]: currentPlan
  });

  const body = {
    digitalSignatureAgreementId: identifier
  };
  return dispatch(apiCall(POST, url, body))
    .then(response => {
      return response.blob();
    })
    .then(blob => download(blob, `${documentName}.pdf`))
    .then(() =>
      dispatch(enqueueNotificationMessage("File is successfully downloaded"))
    )
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage("There was problem while downloading file")
      )
    );
};

export const getExcelComponents = () => (dispatch, getState) => {
  const state = getState();
  const agency = selectAgency(state);
  const campaign = getCampaign(state);

  const url = generateUrl(GET_PLAN_EXPORT_COMPONENTS, {
    agency,
    debtorId: campaign.debtorId,
    campaignId: campaign.campaignId
  });

  return dispatch(apiCall(GET, url))
    .then(response => response)
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage(
          "There was problem while creating export dialog."
        )
      )
    );
};

export const getGroups = () => (dispatch, getState) => {
  const state = getState();
  const agency = selectAgency(state);
  const campaign = getCampaign(state);
  const email = selectUserName(state);

  const url = generateUrl(GET_GROUPS, {
    agency,
    debtorId: campaign.debtorId,
    campaignId: campaign.campaignId,
    email
  });

  return dispatch(apiCall(GET, url))
    .then(response => response)
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage("There was problem while loading groups.")
      )
    );
};

export const getDigitalSignatureAgreementStatus = digitalSignatureAgreementId => (
  dispatch,
  getState
) => {
  const state = getState();
  const agency = selectAgency(state);
  const campaign = getCampaign(state);

  const url = generateUrl(GET_DIGITAL_SIGNATURE_AGREEMENT_STATUS, {
    agency,
    debtorId: campaign.debtorId,
    campaignId: campaign.campaignId,
    digitalSignatureAgreementId
  });

  return dispatch(apiCall(GET, url))
    .then(response => response)
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage(
          "There was problem while fetching agreement status."
        )
      )
    );
};

export const setFlightForMbs = payload => dispatch =>
  dispatch({ type: PLAN_SET_FLIGHT_MBS, payload });

export const resetFlightForMbs = () => dispatch =>
  dispatch({ type: PLAN_RESET_FLIGHT_MBS });

export const setPlanForMbs = payload => dispatch =>
  dispatch({ type: PLAN_SET_PLAN_MBS, payload });

export const resetPlanForMbs = () => dispatch =>
  dispatch({ type: PLAN_RESET_PLAN_MBS });

export const clearMultipleSelections = () => dispatch => {
  dispatch(setDeselectAllOrdersWithStatus());
  dispatch(clearOrdersForDivide());
};

export function doNotNotifyInsertionChange(planId) {
  return (dispatch, getState) => {
    const agencyID = selectAgency(getState());

    const url = generateUrl(DO_NOT_NOTIFY_INSERTION_CHANGE, {
      agency: agencyID,
      [PLAN_MODEL_ID]: planId
    });

    return dispatch(apiCall(POST, url)).then(
      response => {
        return response;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateIsDivideEvenly(value) {
  return dispatch => {
    dispatch({
      type: PLAN_UPDATE_IS_DIVIDE_INSERTION_EVENLY,
      payload: value
    });
  };
}
